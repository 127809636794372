<div class="detail-table-row-wrapper">
	<table mat-table aria-label="table" matSort matSortDisableClear [dataSource]="dataSource">
		<ng-container [matColumnDef]="COLUMN_SELECT">
			<th mat-header-cell *matHeaderCellDef>
				<mat-checkbox
					(change)="$event ? onMasterToggle() : null"
					[checked]="hasSelectedRows() && isAllSelected()"
					[indeterminate]="hasSelectedRows() && !isAllSelected()"
					[disabled]="isEditMode()"
					matTooltip="{{ 'i18n.app.edittable.checkbox.all.tooltip' | translate }}"
				>
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let row">
				<mat-checkbox
					(click)="$event.stopPropagation()"
					(change)="$event ? onToggleSelection(row) : null"
					[checked]="isRowSelected(row)"
					[disabled]="isEditMode()"
					matTooltip="{{ 'i18n.app.edittable.checkbox.single.tooltip' | translate }}"
				>
				</mat-checkbox>
			</td>
		</ng-container>
		<ng-container [matColumnDef]="COLUMN_TITLE">
			<th mat-header-cell mat-sort-header *matHeaderCellDef (click)="onClickOnHeader()">{{ "i18n.shared.datasetlink.title" | translate }}</th>
			<td mat-cell *matCellDef="let element; let i = index">
				<mat-form-field [hidden]="!isRowEditMode(i)">
					<input
						#parentCodeInput
						matInput
						(input)="onParentCodeInputChanged(parentCodeInput.value)"
						[matAutocomplete]="parentCodeAutocomplete"
						[value]="element.title | fallback: currentLanguage"
						[required]="onCheckParentCodeValue(parentCodeInput.value)"
						(click)="onClickOnTitle(parentCodeInput.value)"
					/>
					<mat-autocomplete #parentCodeAutocomplete="matAutocomplete" autoActiveFirstOption="true">
						<mat-option
							*ngFor="let parentCodeValue of searchedDatasetTitle"
							[value]="parentCodeValue"
							(onSelectionChange)="onParentCodeSelection(parentCodeValue!)"
						>
							<span class="mat-option-wrapper">
								<span class="mat-option-text">
									{{ parentCodeValue }}
								</span>
								<span class="mat-option-filler"></span>
								<span class="mat-option-status">
									<mat-chip-set>
										<mat-chip [class]="statusList.get(parentCodeValue!) | registrationstatusclass">{{
											"i18n.shared.status.registrationstatus." + statusList.get(parentCodeValue!)!.replace(" ", "").toLowerCase() | translate
										}}</mat-chip>
									</mat-chip-set>
								</span>
								<span class="mat-option-level">
									<mat-chip-set>
										<mat-chip [class]="publicationList.get(parentCodeValue!) | publicationlevelclass">{{
											"i18n.shared.status.publicationlevel." + publicationList.get(parentCodeValue!)!.replace(" ", "").toLowerCase() | translate
										}}</mat-chip>
									</mat-chip-set>
								</span>
							</span>
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
				<span *ngIf="!isRowEditMode(i)">{{ element.title | fallback: currentLanguage }}</span>
			</td>
		</ng-container>
		<ng-container [matColumnDef]="COLUMN_IDENTIFIER">
			<th mat-header-cell mat-sort-header *matHeaderCellDef (click)="onClickOnHeader()">{{ "i18n.shared.datasetlink.identifier" | translate }}</th>
			<td mat-cell *matCellDef="let element">
				{{ element.identifiers | arraytostring }}
			</td>
		</ng-container>
		<ng-container [matColumnDef]="COLUMN_STATUS">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.page.catalog.table.column.registrationstatus.title" | translate }}
			</th>
			<td mat-cell *matCellDef="let element">
				<div *ngIf="element.registrationStatus">
					<mat-chip-set>
						<mat-chip [class]="element.registrationStatus | registrationstatusclass"
							><div>
								{{ "i18n.shared.status.registrationstatus." + element.registrationStatus.replace(" ", "").toLowerCase() | translate }}
							</div>
							<mat-icon
								svgIcon="clock"
								*ngIf="element.registrationStatusProposal"
								[matTooltip]="
									('i18n.shared.proposal.status' | translate) +
									('i18n.shared.status.registrationstatus.' + element.registrationStatusProposal.replace(' ', '').toLowerCase() | translate)
								"
							></mat-icon
						></mat-chip>
					</mat-chip-set>
				</div>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_PUBLICATION">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.page.catalog.table.column.publicationlevel.title" | translate }}
			</th>
			<td mat-cell *matCellDef="let element">
				<div class="status-chip status-chip-info" *ngIf="element.publicationLevel">
					<mat-chip-set>
						<mat-chip [class]="element.publicationLevel | publicationlevelclass"
							><div>
								{{ "i18n.shared.status.publicationlevel." + element.publicationLevel.replace(" ", "").toLowerCase() | translate }}
							</div>
							<mat-icon
								svgIcon="clock"
								*ngIf="element.publicationLevelProposal"
								[matTooltip]="
									('i18n.shared.proposal.publication' | translate) +
									('i18n.shared.status.publicationlevel.' + element.publicationLevelProposal.replace(' ', '').toLowerCase() | translate)
								"
							></mat-icon
						></mat-chip>
					</mat-chip-set>
				</div>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_ACTIONS">
			<th mat-header-cell mad-sort-header *matHeaderCellDef>
				<div class="action-wrapper">
					<div class="action-button">
						<button
							obButton="secondary"
							mat-icon-button
							matTooltip="{{ 'i18n.app.edittable.button.deleteSelected.tooltip' | translate }}"
							(click)="onRemoveSelectedRows()"
							[disabled]="hasSelectedRows() === false"
						>
							<mat-icon svgIcon="unlink" class="ob-icon"></mat-icon>
						</button>
					</div>
					<div class="action-button">
						<button
							obButton="secondary"
							mat-icon-button
							(click)="onAddRow()"
							[disabled]="!canAdd()"
							matTooltip="{{ 'i18n.app.edittable.button.addrow.tooltip' | translate }}"
						>
							<mat-icon svgIcon="plus" class="ob-icon"></mat-icon>
						</button>
					</div>
				</div>
			</th>
			<td mat-cell *matCellDef="let row; let i = index">
				<div class="action-wrapper">
					<div class="action-button" *ngIf="isRowEditMode(i)">
						<button
							obButton="secondary"
							mat-icon-button
							(click)="onDiscard()"
							[disabled]="hasSelectedRows()"
							matTooltip="{{ 'i18n.app.edittable.button.discard.tooltip' | translate }}"
						>
							<mat-icon svgIcon="cancel" class="ob-icon"></mat-icon>
						</button>
					</div>
					<div class="action-button" *ngIf="isRowEditMode(i)">
						<button
							obButton="secondary"
							mat-icon-button
							(click)="onSave(row)"
							[disabled]="!canSave(i)"
							matTooltip="{{ 'i18n.app.edittable.button.save.tooltip' | translate }}"
						>
							<mat-icon svgIcon="checkmark" class="ob-icon"></mat-icon>
						</button>
					</div>
					<div class="action-button" *ngIf="canRemove()">
						<button
							obButton="secondary"
							mat-icon-button
							(click)="onRemoveRow(i, row)"
							[disabled]="hasSelectedRows()"
							matTooltip="{{ 'i18n.page.datasets.tabs.content.unlinkbutton.tooltip' | translate }}"
						>
							<mat-icon svgIcon="unlink" class="ob-icon"></mat-icon>
						</button>
					</div>
				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>

	<div class="table-info-box">
		<app-infobox-no-content [datasource]="dataSource.data"></app-infobox-no-content>
	</div>
</div>
