<div [formGroup]="parentForm">
	<table mat-table aria-label="table" matSort [dataSource]="dataSource" [formArrayName]="controlName">
		<ng-container [matColumnDef]="COLUMN_SELECT">
			<th mat-header-cell *matHeaderCellDef>
				<mat-checkbox
					(change)="$event ? onMasterToggle() : null"
					[checked]="hasSelectedRows() && isAllSelected()"
					[indeterminate]="hasSelectedRows() && !isAllSelected()"
					[disabled]="isEditMode()"
					matTooltip="{{ 'i18n.app.edittable.checkbox.all.tooltip' | translate }}"
				>
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let row">
				<mat-checkbox
					(click)="$event.stopPropagation()"
					(change)="$event ? onToggleSelection(row) : null"
					[checked]="isRowSelected(row)"
					[disabled]="isEditMode()"
					matTooltip="{{ 'i18n.app.edittable.checkbox.single.tooltip' | translate }}"
				>
				</mat-checkbox>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_ACTIONS">
			<th mat-header-cell *matHeaderCellDef>
				<div class="action-wrapper">
					<div class="action-button">
						<button
							id="{{ controlName + '-deleteSelected-row' }}"
							obButton="secondary"
							mat-icon-button
							matTooltip="{{ 'i18n.app.edittable.button.deleteSelected.tooltip' | translate }}"
							(click)="onRemoveSelectedRows()"
							[disabled]="hasSelectedRows() === false"
							type="button"
						>
							<mat-icon svgIcon="trash" class="ob-icon"></mat-icon>
						</button>
					</div>
					<div class="action-button">
						<button
							id="{{ controlName + '-add-row' }}"
							obButton="secondary"
							mat-icon-button
							(click)="onAddRow()"
							[disabled]="!canAdd()"
							matTooltip="{{ 'i18n.app.edittable.button.addrow.tooltip' | translate }}"
							type="button"
						>
							<mat-icon svgIcon="plus" class="ob-icon"></mat-icon>
						</button>
					</div>
				</div>
			</th>
			<td mat-cell *matCellDef="let row; let i = index">
				<div class="action-wrapper">
					<div class="action-button" *ngIf="isRowEditMode(i)">
						<button
							id="{{ controlName + '-discard-row' + i }}"
							obButton="secondary"
							mat-icon-button
							(click)="onDiscard()"
							[disabled]="hasSelectedRows()"
							matTooltip="{{ 'i18n.app.edittable.button.discard.tooltip' | translate }}"
							type="button"
						>
							<mat-icon svgIcon="cancel" class="ob-icon"></mat-icon>
						</button>
					</div>
					<div class="action-button" *ngIf="isRowEditMode(i)">
						<button
							id="{{ controlName + '-save-row' + i }}"
							obButton="secondary"
							mat-icon-button
							(click)="onSave()"
							[disabled]="!canSave(i)"
							matTooltip="{{ 'i18n.app.edittable.button.save.tooltip' | translate }}"
							type="button"
						>
							<mat-icon svgIcon="checkmark" class="ob-icon"></mat-icon>
						</button>
					</div>
					<div class="action-button" *ngIf="canEdit()">
						<button
							id="{{ controlName + '-edit-row' + i }}"
							obButton="secondary"
							mat-icon-button
							(click)="onEditRow(i)"
							[disabled]="hasSelectedRows()"
							matTooltip="{{ 'i18n.app.edittable.button.edit.tooltip' | translate }}"
							type="button"
						>
							<mat-icon svgIcon="pen" class="ob-icon"></mat-icon>
						</button>
					</div>
					<div class="action-button" *ngIf="canRemove()">
						<button
							id="{{ controlName + '-remove' + i }}"
							obButton="secondary"
							mat-icon-button
							(click)="onRemoveRow(i)"
							[disabled]="hasSelectedRows()"
							matTooltip="{{ 'i18n.app.edittable.button.remove.tooltip' | translate }}"
							type="button"
						>
							<mat-icon svgIcon="trash" class="ob-icon"></mat-icon>
						</button>
					</div>
				</div>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_GERMAN">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.page.datasets.description.edit.german.language.heading.column" | translate }}
			</th>
			<td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" [title]="COLUMN_GERMAN">
				<mat-form-field [hidden]="!isRowEditMode(i)">
					<input
						id="{{ controlName + '-de' + i }}"
						formControlName="de"
						matInput
						(keyup)="type(element, $event.target.value, COLUMN_GERMAN)"
						[value]="element?.label?.de"
						#focusInputField
					/>
				</mat-form-field>
				<span *ngIf="!isRowEditMode(i)">{{ element.de }}</span>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_FRENCH">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.page.datasets.description.edit.french.language.heading.column" | translate }}
			</th>
			<td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" [title]="COLUMN_FRENCH">
				<mat-form-field [hidden]="!isRowEditMode(i)">
					<input
						id="{{ controlName + '-fr' + i }}"
						formControlName="fr"
						matInput
						(keyup)="type(element, $event.target.value, COLUMN_FRENCH)"
						[value]="element.fr"
					/>
				</mat-form-field>
				<span *ngIf="!isRowEditMode(i)">{{ element.fr }}</span>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_ITALIAN">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.page.datasets.description.edit.italian.language.heading.column" | translate }}
			</th>
			<td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" [title]="COLUMN_ITALIAN">
				<mat-form-field [hidden]="!isRowEditMode(i)">
					<input
						id="{{ controlName + '-it' + i }}"
						formControlName="it"
						matInput
						(keyup)="type(element, $event.target.value, COLUMN_ITALIAN)"
						[value]="element.it"
					/>
				</mat-form-field>
				<span *ngIf="!isRowEditMode(i)">{{ element.it }}</span>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_ENGLISH">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.page.datasets.description.edit.english.language.heading.column" | translate }}
			</th>
			<td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" [title]="COLUMN_ENGLISH">
				<mat-form-field [hidden]="!isRowEditMode(i)">
					<input
						id="{{ controlName + '-en' + i }}"
						formControlName="en"
						matInput
						(keyup)="type(element, $event.target.value, COLUMN_ENGLISH)"
						[value]="element.en"
					/>
				</mat-form-field>
				<span *ngIf="!isRowEditMode(i)">{{ element.en }}</span>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns"></tr>
	</table>

	<div class="table-info-box">
		<app-infobox-no-content [datasource]="dataSource.data"></app-infobox-no-content>
	</div>
</div>
