import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	SimpleChanges,
	ViewChild,
	ViewChildren
} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {SelectionModel} from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';
import {FormControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
	Agent,
	DatasetInputClient,
	IAgent,
	IQualifiedAttribution,
	MultiLanguage,
	QualifiedAttribution,
	VocabularyClient,
	VocabularyEntry
} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {Observable, Subject} from 'rxjs';
import {map, shareReplay, startWith, takeUntil} from 'rxjs/operators';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {DatasetInjectablesService} from '../../datasets/services/dataset-injectables.service';
import {ObNotificationService} from '@oblique/oblique';
import {ActivatedRoute} from '@angular/router';

@Component({
	selector: 'app-edit-table-qualified-attribution',
	templateUrl: './edit-table-qualified-attribution.component.html',
	styleUrls: ['./edit-table-qualified-attribution.component.scss']
})
export class EditTableQualifiedAttributionComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {
	@ViewChild(MatSort) sort!: MatSort;
	@ViewChildren('focusInputField') focusInputFields!: QueryList<ElementRef>;
	@Input() parentForm!: any;
	@Input() dto: any;
	@Input() controlName!: string;
	@Output() edit: EventEmitter<boolean> = new EventEmitter();
	public dataSource = new MatTableDataSource<IQualifiedAttribution>([]);
	public attributionRole$: Observable<VocabularyEntry[]> | undefined;
	public currentLanguage: string;
	public datasetId: string | undefined;
	public filteredAgents$!: Observable<IAgent[]>;
	public filteredAgentsControl = new FormControl();
	public agents: IAgent[] = [];

	COLUMN_HAD_ROLE = 'hadRole';
	COLUMN_AGENT = 'agent';
	COLUMN_ACTIONS = 'actions';

	displayedColumns: string[] = [this.COLUMN_HAD_ROLE, this.COLUMN_AGENT, this.COLUMN_ACTIONS];

	private readonly attributionRole: string = 'VOCAB_I14Y_ATTRIBUTION_ROLE';
	private initialRowValue: IQualifiedAttribution;
	private isEditing = false;
	private isAddMode = false;
	private rowIndex: number | undefined;
	private selection = new SelectionModel<IQualifiedAttribution>(true, []);
	private readonly unsubscribe$ = new Subject();

	constructor(
		private readonly vocabularyClient: VocabularyClient,
		private readonly translate: TranslateService,
		private readonly datasetInputClient: DatasetInputClient,
		private readonly datasetInjectablesService: DatasetInjectablesService,
		private readonly notification: ObNotificationService,
		private readonly route: ActivatedRoute
	) {
		this.currentLanguage = this.translate.currentLang;
		this.initialRowValue = this.newEntry();
		this.attributionRole$ = this.vocabularyClient.getByIdentifier(this.attributionRole).pipe(
			map(response => response.result),
			shareReplay(1)
		);
		this.getAgents();
	}

	ngAfterViewInit(): void {
		this.dataSource.sort = this.sort;
	}

	ngOnChanges(changes: SimpleChanges) {
		const change = changes.dto;
		if (change.currentValue !== undefined) {
			this.dataSource = new MatTableDataSource<IQualifiedAttribution>(this.dto);
			this.initResourceToTableData();
		}
	}

	ngOnInit() {
		this.datasetId = this.route.snapshot.params.id;

		if (this.filteredAgentsControl) {
			this.filteredAgents$ = this.filteredAgentsControl.valueChanges.pipe(
				startWith(''),
				map(value => (typeof value === 'string' ? value : value.name[this.currentLanguage])),
				map(term => (term ? this.filterAgents(term) : this.agents))
			);
		}

		this.translate.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe((language: LangChangeEvent) => {
			this.currentLanguage = language.lang;
		});
	}

	ngOnDestroy() {
		this.unsubscribe$.next(1);
		this.unsubscribe$.complete();
	}

	getAgents(): void {
		this.datasetInjectablesService.agentClient
			.get()
			.pipe(shareReplay(1))
			.subscribe(response => {
				this.agents = response.result;
			});
	}

	onMasterToggle(): void {
		if (this.isAllSelected()) {
			this.selection.clear();
		} else {
			this.dataSource.data.forEach(row => this.selection.select(row));
		}
	}

	updateDto() {
		const id = this.route.snapshot.params.id;
		this.datasetInjectablesService.datasetInputClient.getById(id).subscribe(response => {
			this.dto = response.result;
			if (this.dto.qualifiedAttribution) {
				this.dataSource.data = this.dto.qualifiedAttribution;
			}
		});
	}

	onRemoveRow(index: number, row?: IQualifiedAttribution): void {
		if (row?.id) {
			this.datasetInputClient.deleteQualifiedAttributionByQualifiedAttributionId(row?.id as string).subscribe(
				_ => {
					this.dataSource.data.splice(index, 1);
					this.resourceControl.removeAt(index);
					if (!this.isAddMode) {
						this.parentForm.markAsDirty();
					}
					this.refreshDatabinding();
					this.showSuccessDeletedNotification();
				},
				_ => this.showErrorNotification()
			);
		} else {
			this.dataSource.data.splice(index, 1);
			this.resourceControl.removeAt(index);
			if (!this.isAddMode) {
				this.parentForm.markAsDirty();
			}
			this.refreshDatabinding();
		}
	}

	onRemoveSelectedRows(): void {
		this.selection.selected.forEach(item => {
			const index: number = this.dataSource.data.findIndex((d: IQualifiedAttribution) => d === item);
			this.onRemoveRow(index);
		});
		this.selection = new SelectionModel<IQualifiedAttribution>(true, []);
	}

	onAddRow(): void {
		const numRows = this.dataSource.data.push(this.newEntry());
		this.filteredAgentsControl.setValue('');

		this.parentForm.get(this.controlName).push(
			new UntypedFormGroup({
				hadRole: new UntypedFormControl({}, [Validators.required]),
				agent: new UntypedFormControl({}, [Validators.required]),
				id: new UntypedFormControl(''),
				datasetQualifiedAttributionId: new UntypedFormControl('')
			})
		);

		this.isAddMode = true;
		this.UpdateIsEditing(true);
		this.rowIndex = numRows - 1;
		this.refreshDatabinding();
		this.setFocus();
	}

	type(ele: any, value: any, val: any) {
		ele[val] = value;
	}

	onDiscard() {
		if (this.isAddMode) {
			this.onRemoveRow(this.rowIndex!);
			this.isAddMode = false;
		} else {
			this.dataSource.data[this.rowIndex!] = this.copyRowValue(this.initialRowValue);
		}

		this.initResourceToTableData();
		this.refreshDatabinding();
		this.initialRowValue = this.newEntry();
		this.UpdateIsEditing(false);
		this.updateDto();
	}

	onEditRow(rowIndex: number): void {
		this.initialRowValue = this.copyRowValue(this.dataSource.data[rowIndex]);
		this.filteredAgentsControl.setValue(this.parentForm.controls.qualifiedAttribution.get(rowIndex.toString()).get('agent')?.value);
		this.UpdateIsEditing(true);
		this.rowIndex = +rowIndex;
		this.setFocus();
	}

	onSave(row: IQualifiedAttribution): void {
		let postPayload = new QualifiedAttribution({...(row as QualifiedAttribution), datasetQualifiedAttributionId: this.datasetId});

		if (this.isEditing && !this.isAddMode) {
			this.datasetInputClient.putQualifiedAttributionByBody(postPayload).subscribe(
				_ => {
					this.showSuccessNotification();
					this.refreshDatabinding();
					this.dataSource.filter = '';
					this.setFocus();
				},
				_ => this.showErrorNotification()
			);
		} else if (this.isEditing && this.isAddMode) {
			this.datasetInputClient.postQualifiedAttributionByBody(postPayload).subscribe(
				_ => {
					this.showSuccessNotification();
					this.updateDto();
					this.refreshDatabinding();
					this.dataSource.filter = '';
					this.setFocus();
				},
				_ => this.showErrorNotification()
			);
		}

		this.initResourceToTableData();
		this.isAddMode = false;
		this.UpdateIsEditing(false);
	}

	canAdd(): boolean {
		return !this.isEditing && !this.hasSelectedItems();
	}

	canEdit(): boolean {
		return !this.isEditing;
	}

	canRemove(): boolean {
		return !this.isEditing;
	}

	canSave(rowIndex: number): boolean {
		if (this.isRowEditMode(rowIndex)) {
			if (this.dataSource.data[rowIndex].hadRole?.code && this.dataSource.data[rowIndex].agent?.id) {
				return true;
			}
		}
		return false;
	}

	canSelect(): boolean {
		return !this.isEditing;
	}

	hasSelectedRows(): boolean {
		return this.selection.hasValue();
	}

	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	isEditMode(): boolean {
		return this.isEditing;
	}

	isRowEditMode(index: number): boolean {
		return this.isEditing && this.rowIndex === index;
	}

	isRowSelected(row: IQualifiedAttribution): boolean {
		return this.selection.isSelected(row);
	}

	onToggleSelection(row: IQualifiedAttribution): void {
		this.selection.toggle(row);
	}

	compareType(prev: any, next: any): boolean {
		return prev.code === next.code;
	}

	displayValue = (user: any) => {
		return user && user.name[this.currentLanguage] ? user.name[this.currentLanguage] : '';
	};

	private showSuccessNotification(): void {
		this.notification.success('i18n.app.notification.save.succeeded');
	}

	private showErrorNotification(): void {
		this.notification.error('i18n.app.notification.save.error');
	}

	private showSuccessDeletedNotification(): void {
		this.notification.success('i18n.app.notification.deleted');
	}

	private filterAgents(term: string): IAgent[] {
		const filterTerm = term.toLowerCase();

		// @ts-ignore
		return this.agents.filter((option: IAgent) => option.name[this.currentLanguage].toLowerCase().includes(filterTerm));
	}

	private copyRowValue(source: IQualifiedAttribution): IQualifiedAttribution {
		return {
			hadRole: source?.hadRole,
			agent: source?.hadRole,
			id: source?.id,
			datasetQualifiedAttributionId: source?.datasetQualifiedAttributionId
		};
	}

	private hasSelectedItems(): boolean {
		return !this.selection.isEmpty();
	}

	private initResourceToTableData(): void {
		this.parentForm.setControl(
			this.controlName,
			new UntypedFormArray(
				this.dataSource.data.map(
					item =>
						new UntypedFormGroup({
							agent: new UntypedFormControl(item?.agent),
							hadRole: new UntypedFormControl(item?.hadRole),
							id: new UntypedFormControl(item?.id),
							datasetQualifiedAttributionId: new UntypedFormControl(item?.datasetQualifiedAttributionId)
						})
				)
			)
		);
	}

	private newEntry(): IQualifiedAttribution {
		return {
			hadRole: new VocabularyEntry({
				code: undefined,
				name: new MultiLanguage({
					de: undefined,
					fr: undefined,
					it: undefined,
					en: undefined,
					rm: undefined
				})
			}),
			agent: new Agent({
				id: undefined,
				name: new MultiLanguage({
					de: undefined,
					fr: undefined,
					it: undefined,
					en: undefined,
					rm: undefined
				})
			}),
			id: undefined,
			datasetQualifiedAttributionId: undefined
		};
	}

	private refreshDatabinding(): void {
		this.dataSource.filter = '';
	}

	private setFocus(): void {
		setTimeout(() => {
			this.focusInputFields.get(this.rowIndex!)?.nativeElement.focus();
		});
	}

	private UpdateIsEditing(value: boolean) {
		this.isEditing = value;
		this.edit.emit(value);
	}

	private get resourceControl(): any {
		return this.parentForm.get(this.controlName) as UntypedFormArray;
	}
}
