<div class="action-wrapper search-actions">
	<div class="action-button">
		<button (click)="setListView()" mat-icon-button [obButton]="isTableView ? 'secondary' : 'primary'" type="button">
			<mat-icon svgIcon="list"></mat-icon>
		</button>
	</div>
	<div class="action-button">
		<button (click)="setTableView()" mat-icon-button [obButton]="!isTableView ? 'secondary' : 'primary'" type="button">
			<mat-icon svgIcon="cards"></mat-icon>
		</button>
	</div>
</div>

<div *ngIf="!isTableView; else listView">
	<table
		aria-label="table"
		class="ob-table ob-table-plain no-link-decoration"
		mat-table
		matSort
		id="catalog-table"
		[dataSource]="dataSource"
		(matSortChange)="sortData($event)"
	>
		<ng-container [matColumnDef]="COLUMN_TITLE">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.page.catalog.table.column.dataset.title" | translate }}
			</th>
			<td mat-cell *matCellDef="let element">
				<a [routerLink]="getRouterLink(element)" [queryParams]="getBackTo()" routerLinkActive="router-link-active">
					{{ element.title | fallback: currentLanguage }}
				</a>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_PUBLISHERNAME">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.page.catalog.table.column.publisher.title" | translate }}
			</th>
			<td mat-cell *matCellDef="let element">
				{{ element.publisherName | fallback: currentLanguage }}
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_TYPE">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.page.catalog.table.column.type.title" | translate }}
			</th>
			<td mat-cell *matCellDef="let element">
				{{ "i18n.shared.filters.types." + getType(element) | translate }}
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_CONCEPTTYPE">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.page.catalog.table.column.concepttype.title" | translate }}
			</th>
			<td mat-cell *matCellDef="let element">
				{{ "i18n.shared.filters.concepttypes." + getConceptType(element.conceptValueType) | translate }}
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_VERSION">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "i18n.page.catalog.table.column.version.title" | translate }}</th>
			<td mat-cell *matCellDef="let element">{{ element?.version ?? "-" }}</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_STATUS">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.page.catalog.table.column.registrationstatus.title" | translate }}
			</th>
			<td mat-cell *matCellDef="let element">
				<div *ngIf="element.registrationStatus">
					<mat-chip-set>
						<mat-chip [class]="element.registrationStatus | registrationstatusclass"
							><span>
								{{ "i18n.shared.status.registrationstatus." + element.registrationStatus.replace(" ", "").toLowerCase() | translate }}
							</span>
							<mat-icon
								svgIcon="clock"
								*ngIf="element.registrationStatusProposal"
								[matTooltip]="
									('i18n.shared.proposal.status' | translate) +
									('i18n.shared.status.registrationstatus.' + element.registrationStatusProposal.replace(' ', '').toLowerCase() | translate)
								"
							></mat-icon
						></mat-chip>
					</mat-chip-set>
				</div>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_PUBLICATION">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.page.catalog.table.column.publicationlevel.title" | translate }}
			</th>
			<td mat-cell *matCellDef="let element">
				<div *ngIf="element.publicationLevel">
					<mat-chip-set>
						<mat-chip [class]="element.publicationLevel | publicationlevelclass"
							><span>
								{{ "i18n.shared.status.publicationlevel." + element.publicationLevel.replace(" ", "").toLowerCase() | translate }}
							</span>
							<mat-icon
								svgIcon="clock"
								*ngIf="element.publicationLevelProposal"
								[matTooltip]="
									('i18n.shared.proposal.publication' | translate) +
									('i18n.shared.status.publicationlevel.' + element.publicationLevelProposal.replace(' ', '').toLowerCase() | translate)
								"
							></mat-icon
						></mat-chip>
					</mat-chip-set>
				</div>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_VALID_FROM">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "i18n.page.catalog.table.column.validfrom.title" | translate }}</th>
			<td mat-cell *matCellDef="let element">{{ getFormattedDate(element?.validFrom) }}</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_VALID_TO">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "i18n.page.catalog.table.column.validto.title" | translate }}</th>
			<td mat-cell *matCellDef="let element">{{ getFormattedDate(element?.validTo) }}</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_ACTIONS">
			<th mat-header-cell mat-sort-header *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let element; let i = index">
				<div class="action-wrapper">
					<div class="action-button">
						<a
							id="view-detail-button-{{ i }}"
							obButton="secondary"
							mat-icon-button
							type="button"
							matTooltip="{{ 'i18n.page.catalog.table.column.buttons.viewdetail.tooltip' | translate }}"
							[routerLink]="getRouterLink(element)"
							[queryParams]="getBackTo()"
							routerLinkActive="router-link-active"
						>
							<mat-icon svgIcon="eye" class="ob-icon"></mat-icon>
						</a>
					</div>
				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>
</div>

<ng-template #listView>
	<div class="search-result-item" *ngFor="let result of dataSource.data">
		<div class="title-wrapper">
			<h3>
				<a [routerLink]="getRouterLink(result)" [queryParams]="getBackTo()" routerLinkActive="router-link-active">{{
					result?.title | fallback: currentLanguage
				}}</a>
			</h3>
			<div *ngIf="getType(result)" class="result-type">
				{{ "i18n.shared.filters.types." + getType(result) | translate }}
			</div>
		</div>

		<div *ngIf="result?.description">
			{{ result?.description | fallback: currentLanguage }}
		</div>

		<div class="info-list">
			<div class="info-list-item" *ngIf="result?.publisherName">
				<div class="info-list-item-label">{{ "i18n.page.catalog.table.column.publisher" | translate }}:</div>
				<div class="info-list-item-value">
					{{ result?.publisherName | fallback: currentLanguage }}
				</div>
			</div>
			<div class="info-list-item" *ngIf="result?.registrationStatus">
				<div class="info-list-item-label">{{ "i18n.shared.status.registrationstatus.title" | translate }}:</div>
				<div class="info-list-item-value">
					<mat-chip-set>
						<mat-chip [class]="result?.registrationStatus | registrationstatusclass"
							><span>
								{{ "i18n.shared.status.registrationstatus." + result?.registrationStatus.replace(" ", "").toLowerCase() | translate }}
							</span>
							<mat-icon
								svgIcon="clock"
								*ngIf="result?.registrationStatusProposal"
								[matTooltip]="
									('i18n.shared.proposal.status' | translate) +
									('i18n.shared.status.registrationstatus.' + result?.registrationStatusProposal.replace(' ', '').toLowerCase() | translate)
								"
							></mat-icon
						></mat-chip>
					</mat-chip-set>
				</div>
			</div>
			<div class="info-list-item" *ngIf="result?.publicationLevel">
				<div class="info-list-item-label">{{ "i18n.shared.status.publicationlevel.title" | translate }}:</div>
				<div class="info-list-item-value">
					<mat-chip-set>
						<mat-chip [class]="result?.publicationLevel | publicationlevelclass"
							><span>
								{{ "i18n.shared.status.publicationlevel." + result?.publicationLevel.replace(" ", "").toLowerCase() | translate }}
							</span>
							<mat-icon
								svgIcon="clock"
								*ngIf="result?.publicationLevelProposal"
								[matTooltip]="
									('i18n.shared.proposal.publication' | translate) +
									('i18n.shared.status.publicationlevel.' + result?.publicationLevelProposal.replace(' ', '').toLowerCase() | translate)
								"
							></mat-icon
						></mat-chip>
					</mat-chip-set>
				</div>
			</div>
			<div class="info-list-item" *ngIf="result?.version">
				<div class="info-list-item-label">{{ "i18n.page.catalog.table.column.version.title" | translate }}:</div>
				<div class="info-list-item-value">
					{{ result?.version }}
				</div>
			</div>
			<div class="info-list-item" *ngIf="result?.validFrom">
				<div class="info-list-item-label">{{ "i18n.page.catalog.table.column.validfrom.title" | translate }}:</div>
				<div class="info-list-item-value">
					{{ getFormattedDate(result?.validFrom) }}
				</div>
			</div>
			<div class="info-list-item" *ngIf="result?.validTo">
				<div class="info-list-item-label">{{ "i18n.page.catalog.table.column.validto.title" | translate }}:</div>
				<div class="info-list-item-value">
					{{ getFormattedDate(result?.validTo) }}
				</div>
			</div>
		</div>
	</div>
</ng-template>

<div class="table-info-box">
	<app-infobox-no-content [datasource]="dataSource.data"></app-infobox-no-content>
</div>
