<div class="table-wrapper">
	<div class="detail-table-row-wrapper">
		<table aria-label="table" mat-table [dataSource]="dataSource">
			<ng-container [matColumnDef]="COLUMN_TITLE">
				<th mat-header-cell *matHeaderCellDef>
					{{ "i18n.page.datasets.concept.properties.type.relations.table.column.dataset" | translate }}
				</th>
				<td mat-cell *matCellDef="let element">
					<div *ngIf="element.datasetIdentifier === null; else elseBlock">
						{{ element.datasetTitle | fallback: currentLanguage }}
					</div>
					<ng-template #elseBlock>
						<a [routerLink]="'/catalog/datasets/' + element.datasetId + '/description'">
							{{ element.datasetTitle | fallback: currentLanguage }}
						</a>
					</ng-template>
				</td>
			</ng-container>
			<ng-container [matColumnDef]="COLUMN_ENDPPOINTURLS">
				<th mat-header-cell *matHeaderCellDef>
					{{ "i18n.page.datasets.concept.properties.type.relations.table.column.dataelement" | translate }}
				</th>
				<td mat-cell *matCellDef="let element">
					<div *ngIf="element.datasetIdentifier === null; else elseBlock">
						{{ element.dataElementName | fallback: currentLanguage }}
					</div>
					<ng-template #elseBlock>
						<a
							[routerLink]="
								'/catalog/datasets/' + element.datasetId + '/structure/' + element.dataStructureDefinitionId + '/dataelements/' + element.dataElementId
							"
						>
							{{ element.dataElementName | fallback: currentLanguage }}
						</a>
					</ng-template>
				</td>
			</ng-container>
			<ng-container [matColumnDef]="COLUMN_VERSION">
				<th mat-header-cell *matHeaderCellDef>
					{{ "i18n.page.datasets.concept.properties.type.relations.table.column.publisher" | translate }}
				</th>
				<td mat-cell *matCellDef="let element">
					{{ element.agencyName | fallback: currentLanguage }}
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns"></tr>
		</table>
		<app-infobox-no-content [datasource]="dataSource.data"></app-infobox-no-content>
	</div>
</div>
